export class GetDocumentPriority{
    constructor(public id: number){
    
    }
    
    static readonly type="[DocumentPriority] GetDocumentPriority";
    
    }
    
    export class GetAllDocumentPriority{
        constructor(){
        
        }
        
        static readonly type="[DocumentPriority] GetAllDocumentPriority";
        
        }
    
    export class UpdateDocumentPriority{
        constructor(public update:any){
    
        }
    
        static readonly type="[DocumentPriority] UpdateDocumentPriority";
    }
        
    export class CreateDocumentPriority{
        constructor(public create:any){
    
        }
    
        static readonly type="[DocumentPriority] CreateDocumentPriority";
    }


