export class GetVendor{
    constructor(public id: number){
    
    }
    
    static readonly type="[Vendor] GetVendor";
    
    }
    
    export class GetAllVendor{
        constructor(){
        
        }
        
        static readonly type="[Vendor] GetAllVendor";
        
        }
    
    export class UpdateVendor{
        constructor(public update:any){
    
        }
    
        static readonly type="[Vendor] UpdateVendor";
    }
        
    export class CreateVendor{
        constructor(public create:any){
    
        }
    
        static readonly type="[Vendor] CreateVendor";
    }


