import { Component, OnInit } from "@angular/core";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { AuthenticationState } from "src/app/store/authentication/authentication-state";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  //get user id for use across application
  @Select(AuthenticationState.id) id$: Observable<number>;
  id: number;

  @Select(AuthenticationState.authorization) role$: Observable<string>;
  role: string;

  constructor() {
    this.id$.subscribe(id => this.id = id);
    this.role$.subscribe(role => this.role = role);
  }

  ngOnInit(): void {
  }


}
