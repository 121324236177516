export class GetService {
    constructor(public id: number) {

    }

    static readonly type = "[Service] GetService";

}

export class GetAllService {
    constructor() {

    }

    static readonly type = "[Service] GetAllService";

}

export class UpdateService {
    constructor(public serviceId, public update: any) {

    }

    static readonly type = "[Service] UpdateService";
}

export class AddVendor {
    constructor(public serviceId: number, public vendorId: number) {

    }

    static readonly type = "[Service] AddVendor";
}

export class CreateService {
    constructor(public create: any) {

    }

    static readonly type = "[Service] CreateService";
}


