export class GetDocumentType{
    constructor(public id: number){
    
    }
    
    static readonly type="[DocumentType] GetDocumentType";
    
    }
    
    export class GetAllDocumentType{
        constructor(){
        
        }
        
        static readonly type="[DocumentType] GetAllDocumentType";
        
        }
    
    export class UpdateDocumentType{
        constructor(public update:any){
    
        }
    
        static readonly type="[DocumentType] UpdateDocumentType";
    }
        
    export class CreateDocumentType{
        constructor(public create:any){
    
        }
    
        static readonly type="[DocumentType] CreateDocumentType";
    }


