import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
import { throwError, Observable, fromEvent } from 'rxjs';
import { retry, catchError, mapTo, retryWhen, switchMap, map } from 'rxjs/operators';
import { api } from './api-link';

@Injectable({
  providedIn: 'root'
})
export class UserService {


    private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));
  
    get isOnline() {
    return navigator.onLine;
    }


  constructor(private http:HttpClient) { }

  errorHandl(error:any) {
  let errorMessage = '';
  if(error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(errorMessage);
}

httpOptions={
  headers: new HttpHeaders({
    'Content-Type':'application/json'
  })
}



  get(id:number):Observable<User>{
    return this.http.get<User>(api+"/User/"+id).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }

  getAll(params:any):Observable<any>{
    return this.http.get<any>(api+"/User/q",{params:params}).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }

  getProfilePicture(params:any):Observable<Blob> {

    return this.http.get(api+"/User/GetImage", {params:params, responseType: "blob"},);
  }

  getReport(params:any):Observable<Blob> {

    return this.http.get(api+"/User/ExcelReport/", {params:params,responseType:'blob'});
  }




  approve(update: any):Observable<User>{
    return this.http.post<User>(api+'/User/ApproveUser',JSON.stringify(update),this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  update(update: any):Observable<User>{
    return this.http.patch<User>(api+'/User/',JSON.stringify(update),this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  delete(id: number):Observable<User> {
    return this.http.delete<User>(api+'/User/'+id,this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

    create(create: any):Observable<User>{
      return this.http.post<User>(api+'/User',JSON.stringify(create),this.httpOptions).pipe(
        retry(1),
        catchError(this.errorHandl)
      )
    }

    addSignature(create:any, id:number):Observable<any>{
      return this.http.post(api+'/User/'+id+'/Image',JSON.stringify(create),{
        ...this.httpOptions,responseType:'blob'
      }).pipe(
        retry(1),
        catchError(this.errorHandl)
      )
    }


    getSigImage(userId:number,imageId:number):Observable<Blob> {

      return this.http.get(api+"/User/"+userId+"/Image/"+imageId, {responseType:'blob'});
    }

    
   
  



}