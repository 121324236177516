import { Pipe, PipeTransform } from '@angular/core';
import { Department } from '../models/department';

@Pipe({
  name: 'facultyDepartmentPipe'
})
export class FacultyDepartmentPipePipe implements PipeTransform {

  transform(departments: Department[], facultyId:number): Department[] {

    if(facultyId)
    return departments.filter(department => department.facultyId == facultyId);
    else
    return [];
  }

}
