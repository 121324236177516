import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { DocumentPriorityService } from 'src/app/services/documentPriority.service';
import { DocumentPriorityStateModel } from './documentpriority.state-model';
import { GetAllDocumentPriority, GetDocumentPriority, UpdateDocumentPriority, CreateDocumentPriority } from './documentpriority.actions';


@State<DocumentPriorityStateModel>({
    name:'DocumentPriority'
  })
@Injectable()
  export class DocumentPriorityState{
  
    constructor(private service: DocumentPriorityService){
  
    }
  
    @Selector()
    static documentPriorities(state:DocumentPriorityStateModel){
      return state.documentPriorities.filter( object => object.active==true);
    }

    @Selector()
    static DocumentPriority(state:DocumentPriorityStateModel){
      return state.DocumentPriority;
    }
  
  
    @Action(GetAllDocumentPriority)
    getdocumentPriorities({patchState}:StateContext<DocumentPriorityStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          documentPriorities: result.data
        });
      }));
    }

    @Action(GetDocumentPriority)
    getDocumentPriorityMethodById({patchState}:StateContext<DocumentPriorityStateModel>, {id}: GetDocumentPriority){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          DocumentPriority: result
        });
      }));
    }
  
    @Action(UpdateDocumentPriority)
    updateDocumentPriorityMethod({dispatch}:StateContext<DocumentPriorityStateModel>,{update}:UpdateDocumentPriority
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllDocumentPriority());
            }
          )
        )
      }

      @Action(CreateDocumentPriority)
      createDocumentPriority({dispatch, patchState}:StateContext<DocumentPriorityStateModel>,{create}:CreateDocumentPriority
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllDocumentPriority());
              }
            )
          )
        }
  
  
    
  
  }