export class GetDepartment{
    constructor(public id: number){
    
    }
    
    static readonly type="[Department] GetDepartment";
    
    }
    
    export class GetAllDepartment{
        constructor(){
        
        }
        
        static readonly type="[Department] GetAllDepartment";
        
        }
    
    export class UpdateDepartment{
        constructor(public update:any){
    
        }
    
        static readonly type="[Department] UpdateDepartment";
    }
        
    export class CreateDepartment{
        constructor(public create:any){
    
        }
    
        static readonly type="[Department] CreateDepartment";
    }


