export class GetUser{
    constructor(public id: number){
    
    }
    
    static readonly type="[User] GetUser";
    
    }

    export class GetUserToEdit{
        constructor(public id:number){
         
        }
        static readonly type="[User] GetUserToEdit";
    }
    
    export class GetAllUser{
        constructor(public params:any){
        
        }
        
        static readonly type="[User] GetAllUser";
        
        }
    
    export class UpdateUser{
        constructor(public update:any){
    
        }
    
        static readonly type="[User] UpdateUser";
    }


    export class GetProfilePicture{
        constructor(public id:number){
    
        }
    
        static readonly type="[User] GetProfilePicture";
    }

    export class GetSignature{
        constructor(public userId:number, public signatureId:number){
    
        }
    
        static readonly type="[User] GetSignatureID";
    }



    export class DeleteUser{
        constructor(public id:number){
    
        }
    
        static readonly type="[User] DeleteUser";
    }
        
    export class CreateUser{
        constructor(public create:any){
    
        }
    
        static readonly type="[User] CreateUser";
    }

    export class ApproveUser{
        constructor(public payload: any){

        }
        static readonly type="[Admin] Approve User";
    }


    export class AddSignature{
        constructor(public create: any, public userId:number){

        }
        static readonly type="[User] Add Signature";
    }

    export class GetUserReport{
        constructor(public params:any){
        
        }
        
        static readonly type="[User] GetUserReport";
    }

