export class GetCurrency{
    constructor(public id: number){
    
    }
    
    static readonly type="[Currency] GetCurrency";
    
    }
    
    export class GetAllCurrency{
        constructor(){
        
        }
        
        static readonly type="[Currency] GetAllCurrency";
        
        }
    
    export class UpdateCurrency{
        constructor(public update:any){
    
        }
    
        static readonly type="[Currency] UpdateCurrency";
    }
        
    export class CreateCurrency{
        constructor(public create:any){
    
        }
    
        static readonly type="[Currency] CreateCurrency";
    }


