import { Component, OnInit } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { AuthenticationState } from "src/app/store/authentication/authentication-state";
import { GetAllCurrency } from "src/app/store/currency/state/currency.actions";
import { GetAllDocumentNature } from "src/app/store/documentnature/state/documentnature.actions";
import { GetAllDocumentPriority } from "src/app/store/documentpriority/state/documentpriority.actions";
import { GetAllDocumentType } from "src/app/store/documenttype/state/documenttype.actions";
import { GetAllFund } from "src/app/store/fund/state/fund.actions";
import { GetAllRequestType } from "src/app/store/requesttype/state/requesttype.actions";


@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss']
})
export class ApplyComponent implements OnInit {

  @Select(AuthenticationState.id) id$:Observable<number>;
  id:number;
  pageLoaded: boolean = false;

  constructor(private store:Store) {
    this.id$.subscribe(id => this.id=id);
   }

  ngOnInit(): void {
    this.store.dispatch([new GetAllDocumentPriority(), new GetAllDocumentNature(), new GetAllDocumentType(), new GetAllCurrency(), new GetAllFund(),  new GetAllRequestType()]).subscribe(() => { this.pageLoaded = true; })
  }

}
