import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthenticationState } from '../store/authentication/authentication-state';
import { AppError, ResetTimer, Signout } from '../store/authentication/authentication-actions';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

 

  constructor( private _store: Store, private authService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this._store.dispatch(new ResetTimer());



    
    
    var accessToken = this._store.selectSnapshot(AuthenticationState.accessToken)
    if (accessToken) {
      request = this.addToken(request, accessToken);
    }

    return next.handle(request).pipe(catchError(error => {

      if (error.status === 403 && !request.url.toLowerCase().includes("authenticate") && request.method.toLowerCase() == "get") {
        this._store.dispatch(new Signout(this._store.selectSnapshot(AuthenticationState.refreshToken)));
    }
  
      this._store.dispatch(new AppError(typeof(error.error) == 'string' || error.error instanceof Blob? error.error : error.message))

      if (
        request.url.toLowerCase().includes("refreshtoken") ||
        request.url.toLowerCase().includes("authenticate")
    ) {
        // We do another check to see if refresh token failed
        // In this case we want to logout user and to redirect it to login page

        if (request.url.toLowerCase().includes("refreshtoken")) {
            this._store.dispatch(new Signout(this._store.selectSnapshot(AuthenticationState.refreshToken)));
        }

        return throwError(error);
    }

    // If error status is different than 401 we want to skip refresh token
    // So we check that and throw the error if it's the case
    if (error.status !== 401) {
        return throwError(error);
    }

      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(request, next);
      } else {
        return throwError(error);
      }
    }));
            
      
  
  }

 
  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private isRefreshing = false;
private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  if (!this.isRefreshing) {
    this.isRefreshing = true;
    this.refreshTokenSubject.next(null);

    return this.authService.refreshToken(this._store.selectSnapshot(AuthenticationState.refreshToken)).pipe(

      switchMap((token: any) => {
        this.isRefreshing = false;
        this.refreshTokenSubject.next(token.jwt);
        return next.handle(this.addToken(request, token.jwt));
      }),      
      catchError((error: any) => {
        this.isRefreshing = false;

        this._store.dispatch(new Signout(this._store.selectSnapshot(AuthenticationState.refreshToken)));
        return throwError(error);
    })
    );

  } else {
    return this.refreshTokenSubject.pipe(
      filter(token => token != null),
      take(1),
      switchMap(jwt => {
        return next.handle(this.addToken(request, jwt));
      }));
  }


}
}