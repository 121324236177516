export class GetRequestType {
    constructor(public id: number) {

    }

    static readonly type = "[RequestType] GetRequestType";

}

export class GetAllRequestType {
    constructor() {

    }

    static readonly type = "[RequestType] GetAllRequestType";

}


export class CreateRequestTypeCategory {
    constructor(public requestTypeId: number, public create: any) {

    }

    static readonly type = "[RequestType] CreateRequestTypeCategory";

}


export class DeleteRequestTypeCategory {
    constructor(public requestTypeId: number, public categoryId: number) {

    }

    static readonly type = "[RequestType] DeleteRequestTypeCategory";

}

export class UpdateRequestType {
    constructor(public update: any) {

    }

    static readonly type = "[RequestType] UpdateRequestType";
}


export class DeleteRequestType {
    constructor(public id: number) {

    }

    static readonly type = "[RequestType] DeleteRequestType";
}

export class CreateRequestType {
    constructor(public create: any) {

    }

    static readonly type = "[RequestType] CreateRequestType";
}


