import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { UnitService } from 'src/app/services/unit.service';
import { UnitStateModel } from './unit.state-model';
import { GetAllUnit, GetUnit, UpdateUnit, CreateUnit } from './unit.actions';


@State<UnitStateModel>({
    name:'Unit'
  })
@Injectable()
  export class UnitState{
  
    constructor(private service: UnitService){
  
    }
  
    @Selector()
    static units(state:UnitStateModel){
      return state.units.filter( object => object.active==true);
    }

    @Selector()
    static Unit(state:UnitStateModel){
      return state.Unit;
    }
  
  
    @Action(GetAllUnit)
    getunits({patchState}:StateContext<UnitStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          units: result.data
        });
      }));
    }

    @Action(GetUnit)
    getUnitMethodById({patchState}:StateContext<UnitStateModel>, {id}: GetUnit){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          Unit: result
        });
      }));
    }
  
    @Action(UpdateUnit)
    updateUnitMethod({dispatch}:StateContext<UnitStateModel>,{update}:UpdateUnit
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllUnit());
            }
          )
        )
      }

      @Action(CreateUnit)
      createUnit({dispatch, patchState}:StateContext<UnitStateModel>,{create}:CreateUnit
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllUnit());
              }
            )
          )
        }
  
  
    
  
  }