<clr-main-container  >
    <app-header></app-header>
    <div class="content-container" >
        <clr-vertical-nav [clr-nav-level]="2">
            <clr-tree>
                <clr-tree-node [clrExpanded]="true">
                    <clr-icon shape="form" class="is-solid"></clr-icon>
                   <b>Application Forms</b>
                    <clr-tree-node>
                        <a class="clr-treenode-link" routerLink="/apply/investment-requests" routerLinkActive="active">
                           In-Progress Investments
                        </a>
                    </clr-tree-node>

                 </clr-tree-node>
                 
            </clr-tree>
           
        </clr-vertical-nav>
        <div  class="content-area">
            <span class="spinner"  style="margin:3%" *ngIf="!pageLoaded">
                Loading...
              </span>
            <router-outlet  *ngIf="id && pageLoaded"></router-outlet>
        </div>
    </div>
</clr-main-container>
