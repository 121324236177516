import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthenticationState } from '../store/authentication/authentication-state';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {


  @Select(AuthenticationState.authorization) role$: Observable<string>;
  role:string;

  @Select(AuthenticationState.id) id$: Observable<number>;
  id:number;

  constructor() {
    this.role$.subscribe(role => this.role=role);
    this.id$.subscribe(id => this.id=id);
   }

  ngOnInit(): void {
  }

}
