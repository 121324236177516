import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { FundService } from 'src/app/services/fund.service';
import { FundStateModel } from './fund.state-model';
import { GetAllFund, GetFund, UpdateFund, CreateFund, DeleteFund } from './fund.actions';


@State<FundStateModel>({
  name: 'Fund'
})
@Injectable()
export class FundState {

  constructor(private service: FundService) {

  }

  @Selector()
  static funds(state: FundStateModel) {
    return state.funds.filter(object => object.active == true).sort((a, b) => a.name.localeCompare(b.name));
  }

  @Selector()
  static Fund(state: FundStateModel) {
    return state.Fund;
  }


  @Action(GetAllFund)
  getfunds({ patchState }: StateContext<FundStateModel>) {
    return this.service.getAll().pipe(tap(result => {
      patchState({
        funds: result.data
      });
    }));
  }

  @Action(GetFund)
  getFundMethodById({ patchState }: StateContext<FundStateModel>, { id }: GetFund) {
    return this.service.get(id).pipe(tap(result => {
      patchState({
        Fund: result
      });
    }));
  }

  @Action(UpdateFund)
  updateFundMethod({ dispatch }: StateContext<FundStateModel>, { update }: UpdateFund
  ) {
    return this.service.update(update).pipe(
      tap(
        result => {
          dispatch(new GetAllFund());
        }
      )
    )
  }

  @Action(DeleteFund)
  deleteFundMethod({ dispatch }: StateContext<FundStateModel>, { fundId }: DeleteFund
  ) {
    return this.service.delete(fundId).pipe(
      tap(
        result => {
          dispatch(new GetAllFund());
        }
      )
    )
  }


  @Action(CreateFund)
  createFund({ dispatch, patchState }: StateContext<FundStateModel>, { create }: CreateFund
  ) {
    return this.service.create(create).pipe(
      tap(
        result => {
          dispatch(new GetAllFund());
        }
      )
    )
  }




}