import { AuthenticationState } from './authentication/authentication-state';
import { CurrencyState } from './currency/state/currency.state';
import { DepartmentState } from './department/state/department.state';
import { DocumentState } from './document/state/document.state';
import { DocumentNatureState } from './documentnature/state/documentnature.state';
import { DocumentPriorityState } from './documentpriority/state/documentpriority.state';
import { DocumentTypeState } from './documenttype/state/documenttype.state';
import { FacultyState } from './faculty/state/faculty.state';
import { FundState } from './fund/state/fund.state';
import { RequestTypeState } from './requesttype/state/requesttype.state';
import { ServiceState } from './service/state/service.state';
import { SignatoryState } from './signatory/state/signatory.state';
import { StatusState } from './status/state/status.state';
import { UnitState } from './unit/state/unit.state';
import { UserState } from './user/state/user.state';
import { RoleState } from './userrole/state/userrole.state';
import { VendorState } from './vendor/state/vendor.state';

export const appState = [
    AuthenticationState,
    UserState,
    DepartmentState,
    RoleState,
    UnitState,
    ServiceState,
    DocumentState,
    DocumentPriorityState,
    DocumentTypeState,
    DocumentNatureState,
    FundState,
    CurrencyState,
    FacultyState,
    StatusState,
    RequestTypeState,
    SignatoryState,
    VendorState
]