import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { FacultyService } from 'src/app/services/faculty.service';
import { FacultyStateModel } from './faculty.state-model';
import { GetAllFaculty, GetFaculty, UpdateFaculty, CreateFaculty } from './faculty.actions';


@State<FacultyStateModel>({
    name:'Faculty'
  })
@Injectable()
  export class FacultyState{
  
    constructor(private service: FacultyService){
  
    }
  
    @Selector()
    static faculties(state:FacultyStateModel){
      return state.faculties.filter( object => object.active==true).sort((a,b)=> a.name.localeCompare(b.name));
    }

    @Selector()
    static Faculty(state:FacultyStateModel){
      return state.Faculty;
    }
  
  
    @Action(GetAllFaculty)
    getfaculties({patchState}:StateContext<FacultyStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          faculties: result.data
        });
      }));
    }

    @Action(GetFaculty)
    getFacultyMethodById({patchState}:StateContext<FacultyStateModel>, {id}: GetFaculty){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          Faculty: result
        });
      }));
    }
  
    @Action(UpdateFaculty)
    updateFacultyMethod({dispatch}:StateContext<FacultyStateModel>,{update}:UpdateFaculty
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllFaculty());
            }
          )
        )
      }

      @Action(CreateFaculty)
      createFaculty({dispatch, patchState}:StateContext<FacultyStateModel>,{create}:CreateFaculty
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllFaculty());
              }
            )
          )
        }
  
  
    
  
  }