import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { VendorService } from 'src/app/services/vendor.service';
import { VendorStateModel } from './vendor.state-model';
import { GetAllVendor, GetVendor, UpdateVendor, CreateVendor } from './vendor.actions';


@State<VendorStateModel>({
  name: 'Vendor'
})
@Injectable()
export class VendorState {

  constructor(private service: VendorService) {

  }

  @Selector()
  static vendors(state: VendorStateModel) {
    return state.vendors.filter(object => object.active == true).sort();
  }

  @Selector()
  static Vendor(state: VendorStateModel) {
    return state.Vendor;
  }


  @Action(GetAllVendor)
  getvendors({ patchState }: StateContext<VendorStateModel>) {
    return this.service.getAll().pipe(tap(result => {
      patchState({
        vendors: result.data
      });
    }));
  }

  @Action(GetVendor)
  getVendorMethodById({ patchState }: StateContext<VendorStateModel>, { id }: GetVendor) {
    return this.service.get(id).pipe(tap(result => {
      patchState({
        Vendor: result
      });
    }));
  }

  @Action(UpdateVendor)
  updateVendorMethod({ dispatch }: StateContext<VendorStateModel>, { update }: UpdateVendor
  ) {
    return this.service.update(update).pipe(
      tap(
        result => {
          dispatch(new GetAllVendor());
        }
      )
    )
  }

  @Action(CreateVendor)
  createVendor({ dispatch, patchState }: StateContext<VendorStateModel>, { create }: CreateVendor
  ) {
    return this.service.create(create).pipe(
      tap(
        result => {
          dispatch(new GetAllVendor());
        }
      )
    )
  }




}