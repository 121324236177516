export class GetDocumentNature{
    constructor(public id: number){
    
    }
    
    static readonly type="[DocumentNature] GetDocumentNature";
    
    }
    
    export class GetAllDocumentNature{
        constructor(){
        
        }
        
        static readonly type="[DocumentNature] GetAllDocumentNature";
        
        }
    
    export class UpdateDocumentNature{
        constructor(public update:any){
    
        }
    
        static readonly type="[DocumentNature] UpdateDocumentNature";
    }
        
    export class CreateDocumentNature{
        constructor(public create:any){
    
        }
    
        static readonly type="[DocumentNature] CreateDocumentNature";
    }


