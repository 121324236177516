import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthenticationState } from '../store/authentication/authentication-state';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationGuard implements CanActivate {
  constructor(private store:Store){ }

  canActivate() : boolean {

    //if they have a token, allow access
    const token= this.store.selectSnapshot(AuthenticationState.accessToken);
    return token !==undefined;

  }
  
}