import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { UserStateModel } from './user.state-model';
import { GetAllUser, GetUser, UpdateUser, CreateUser, GetUserToEdit,ApproveUser, AddSignature, DeleteUser, GetProfilePicture, GetSignature, GetUserReport } from './user.actions';
import { b64toBlob } from '../../base64toBlob';


@State<UserStateModel>({
    name:'User'
  })
@Injectable()
  export class UserState{
  
    constructor(private service: UserService){
  
    }
  
    @Selector()
    static users(state:UserStateModel){
      return state.users.filter( object => object.active==true).sort((a,b)=> a.lastName.localeCompare(b.lastName));
    }

    @Selector()
    static User(state:UserStateModel){
      return state.User;
    }

    @Selector()
    static editingUser(state:UserStateModel){
      return state.editingUser;
    }

    @Selector()
    static profilePicture(state:UserStateModel){
      return state.profilePicture;
    }

    @Selector()
    static signature(state:UserStateModel){
      return state.signature;
    }


  
    @Action(GetAllUser)
    getusers({patchState}:StateContext<UserStateModel>,{params}: GetAllUser){
      return this.service.getAll(params).pipe(tap(result => {
        patchState({
          users: result.data
        });
      }));
    }

    @Action(GetUserReport)
    getusersReport({patchState}:StateContext<UserStateModel>,{params}: GetUserReport){
      return this.service.getReport(params).pipe(tap(result => {
        var newBlob = new Blob([result], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        const data = window.URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        link.href = data;
        link.download = "User_Report.xlsx";
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
        }, 100);
     

      }));
    }

      

      
    @Action(GetProfilePicture)
    profilePicture({patchState}:StateContext<UserStateModel>,{id}: GetProfilePicture){
      return this.service.getProfilePicture({userId:id}).pipe(tap(async result => {

        patchState({
          profilePicture:null
        });

        var base64 = await result.text();

         var content_type= base64.slice(5,base64.indexOf(";"));

          var blob = b64toBlob(base64.substring(base64.indexOf(";") + 1),content_type,512);

        if(blob != null){
         
         var file = new File([blob], "profilePicture", {type:content_type});
  
         var reader:FileReader= new FileReader();
  
         reader.onload = function(){
          patchState({
            profilePicture: reader.result
          });
        };
  
        reader.readAsDataURL(file);
        }
          
        
     

      }));
    }

    @Action(GetUser,  {cancelUncompleted: true })
    getuserById({patchState}:StateContext<UserStateModel>, {id}: GetUser){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          User: result
        });
      }));
    }

    @Action(GetSignature,  {cancelUncompleted: true })
    getSignature({patchState}:StateContext<UserStateModel>, {userId,signatureId}: GetSignature){
      return this.service.getSigImage(userId,signatureId).pipe(tap(async result => {
        
        var base64 = await result.text();
        
        var content_type= base64.slice(5,base64.indexOf(";"));

        var blob = b64toBlob(base64.substring(base64.indexOf(";") + 1),content_type,512);

       var file = new File([blob], "signature", {type:"image/png"});

       var reader:FileReader= new FileReader();


       reader.onload = function(){
        patchState({
          signature: reader.result
        });
      };

      reader.readAsDataURL(file);

      }));
    }


    @Action(GetUserToEdit,  {cancelUncompleted: true })
    getuserEdit({patchState, dispatch}:StateContext<UserStateModel>, {id}: GetUserToEdit){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          editingUser: result
        });
      }));
    }

    @Action(ApproveUser)
    approve({patchState}:StateContext<UserStateModel>, {payload}: ApproveUser){
      return this.service.approve(payload).pipe(tap(result => {
       
      }));
    }
  
    @Action(UpdateUser)
    updateuser({dispatch}:StateContext<UserStateModel>,{update}:UpdateUser
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             
            }
          )
        )
      }


      @Action(DeleteUser)
      updadteuser({dispatch}:StateContext<UserStateModel>,{id}:DeleteUser
        ){
          return this.service.delete(id).pipe(
            tap(
              result=>
              {
               
              }
            )
          )
        }

      @Action(CreateUser)
      deleteuser({dispatch, patchState}:StateContext<UserStateModel>,{create}:CreateUser
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                
              }
            )
          )
        }

        @Action(AddSignature)
        addSignature({dispatch, patchState}:StateContext<UserStateModel>,{create, userId}:AddSignature
          ){
            return this.service.addSignature(create,userId).pipe(
              tap(
                async result=>
                {
                  var base64 = await result.text();
        
                  var content_type= base64.slice(5,base64.indexOf(";"));
          
                  var blob = b64toBlob(base64.substring(base64.indexOf(";") + 1),content_type,512);
          
                 var file = new File([blob], "signature", {type:"image/png"});
          
                 var reader:FileReader= new FileReader();
          
          
                 reader.onload = function(){
                  patchState({
                    signature: reader.result
                  });
                };
          
                reader.readAsDataURL(file);
                }
              )
            )
          }


  
    
  
  }