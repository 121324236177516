export class Signin {
    static readonly type = '[Authentication] Signin';
  
    constructor(public payload:{userId:number, password:string,  googleReCaptchaResponse:string}) {
  
    }
  }
  
  export class Signout {
    static readonly type = '[Authentication] Signout';
  
    constructor(public refreshToken:string){
      
    }
  }

  export class GetLogs{
    static readonly type = '[Authentication] GetLogs';
  
    constructor(public params?:any){
      
    }
  }

  export class ResetTimer{
    static readonly type='[Authentication] ResetTimer';

    constructor(){

    }
  }

  export class RefreshToken{
    static readonly type='[Authentication] RefreshToken';
    
    constructor(){

    }
  }


  
  export class Decrement {
    static readonly type = 'DECREMENT Counter';
  }
  
  
  
  export class EmptyState{
    static readonly type='[Authentication] PersistLocal';
  
    constructor(){

    }
  }

  export class ResetPassword{

    static readonly type='[Authentication] ResetPassword';

    constructor(public payload: any){

    }

  }


  export class AppError{
    static readonly type='[Authentication] AppError';

    constructor(public error: any){
      
    }
  }

  export class VerifyCode{

    static readonly type='[Authentication] VerifyPassword';

    constructor(public payload: any){
      
    }

  }

  export class CheckEmail{
    static readonly type='[Authentication] CheckEmail';

    constructor(public email: string){

    }
  }

  export class CheckUsername{
    static readonly type='[Authentication] CheckUsername';

    constructor(public username: string){
      
    }

  }

  export class SelectRole{
    static readonly type='[Authentication] SelectRole';

    constructor(public roleId: number){
      
    }

  }