import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin/admin.component';
import { ApplyComponent } from './applications/apply/apply.component';
import { AuthenticationComponent } from './authentication/authentication/authentication.component';
import { AdminGuard } from './guards/admin.guard';
import { AuthenticationGuard } from './guards/authentication-guard.guard';
import { RequestComponent } from './requests/request/request.component';
import { TimelineComponent } from './timeline/timeline.component';

const routes: Routes = [

  {
    path: 'timeline',
    component: TimelineComponent,
    canActivate: [AuthenticationGuard]
  }, {
    path: 'authentication',
    component: AuthenticationComponent,
    loadChildren: () => import('./authentication/authentication.module')
      .then(m => m.AuthenticationModule)
  },
  {
    path: 'apply',
    component: ApplyComponent,
    loadChildren: () => import('./applications/applications.module')
      .then(m => m.ApplicationsModule),
    canActivateChild: [AdminGuard],
    data: { role: ['Requesting Officer', 'Admin'] }
  },
  {
    path: 'request',
    component: RequestComponent,
    loadChildren: () => import('./requests/requests.module')
      .then(m => m.RequestsModule),
    canActivateChild: [AdminGuard],
    data: { role: ['Admin', 'Requesting Officer', 'Authorizing Officer - Department', 'Endorsing Officer', 'Logistics Officer', 'Evaluating Officer', 'Authorizing Officer - Faculty', 'Bursary', 'Procurement'] },

  },
  {
    path: 'management',
    component: AdminComponent,
    loadChildren: () => import('./admin/admin.module')
      .then(m => m.ManagementModule),
    canActivateChild: [AdminGuard],
    data: { role: ['Admin', 'Procurement', 'Bursary'] }
  },
  {
    path: '**',
    redirectTo: '/authentication/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
