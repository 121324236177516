import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Document } from '../models/document';
import { throwError, Observable, fromEvent } from 'rxjs';
import { retry, catchError, mapTo, retryWhen, switchMap } from 'rxjs/operators';
import { api } from './api-link';
import { Service } from '../models/service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {



  private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));

  get isOnline() {
    return navigator.onLine;
  }


  constructor(private http: HttpClient) { }

  errorHandl(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  get(id: number): Observable<Document> {
    return this.http.get<Document>(api + "/Document/" + id).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }

  getPDF(id: number): Observable<Blob> {
    return this.http.get(api + "/Document/RequestForm/" + id, { responseType: 'blob' }).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }

  getAllPending(params: any): Observable<any> {
    return this.http.get<any>(api + "/Document/Pending/q", { params: params }).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }


  getAllUnsaved(): Observable<any> {
    return this.http.get<any>(api + "/Document/Unsaved").pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }

  save(documentId: number) {
    return this.http.post<any>(api + "/Document/SaveDocument/" + documentId, JSON.stringify({}), this.httpOptions).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )
  }


  getAll(params: any): Observable<any> {
    return this.http.get<any>(api + "/Document/q", { params: params }).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }
  getAllApproved(params: any): Observable<any> {
    return this.http.get<any>(api + "/Document/Approved/q", { params: params }).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }


  update(documentId: number, update: any): Observable<Document> {
    return this.http.patch<Document>(api + '/Document/' + documentId, JSON.stringify(update), this.httpOptions).pipe(

      catchError(this.errorHandl)
    )
  }

  returnDocument(documentId: number, signatoryId: number, comment: string): Observable<Document> {
    return this.http.post<Document>(api + '/Document/' + documentId + '/returnTo/' + signatoryId, JSON.stringify({ comment: comment }), this.httpOptions).pipe(

      catchError(this.errorHandl)
    )
  }


  returnDocumentAuthorizing(documentId: number): Observable<Document> {
    return this.http.post<Document>(api + '/Document/SendDocumentToAuthorizingOfficer/' + documentId, this.httpOptions).pipe(

      catchError(this.errorHandl)
    )
  }



  delete(id: any): Observable<Document> {
    return this.http.delete<Document>(api + '/Document/' + id,

    ).pipe(
      catchError(this.errorHandl)
    )
  }





  create(create: any): Observable<Document> {
    return this.http.post<Document>(api + '/Document/', JSON.stringify(create), this.httpOptions).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )
  }

  createType(documentId: any, documentTypeId: any) {
    return this.http.post<DocumentType>(api + '/Document/' + documentId + '/DocumentType/' + documentTypeId, this.httpOptions).pipe(
      catchError(this.errorHandl)
    )
  }

  createNature(documentId: number, documentNatureId: number) {
    return this.http.post<Document>(api + '/Document/' + documentId + '/DocumentNature/' + documentNatureId, this.httpOptions).pipe(
      catchError(this.errorHandl)
    )
  }

  createService(documentId: number, create: any) {
    return this.http.post<Document>(api + '/Document/' + documentId + '/Service', JSON.stringify(create), this.httpOptions).pipe(
      catchError(this.errorHandl)
    )
  }

  updateService(documentId: number, serviceId: number, update: any): Observable<Service> {
    return this.http.patch<Service>(api + '/Document/' + documentId + '/Service/' + serviceId, JSON.stringify(update), this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }


  createServiceComment(documentId: number, serviceId: number, comment: string) {
    return this.http.post<Document>(api + '/Document/' + documentId + '/Service/' + serviceId + '/Comment', JSON.stringify(comment), this.httpOptions).pipe(
      catchError(this.errorHandl)
    )
  }


  createApproval(documentId: number, approval: any) {
    return this.http.post<Document>(api + '/Document/' + documentId + '/Approval', JSON.stringify(approval), this.httpOptions).pipe(
      catchError(this.errorHandl)
    )
  }

  createAttachment(documentId: number, attachment: any) {
    return this.http.post<Document>(api + '/Document/' + documentId + '/Attachment', JSON.stringify(attachment), this.httpOptions).pipe(
      catchError(this.errorHandl)
    )
  }

  deleteService(documentId: number, id: number) {
    return this.http.delete<Document>(api + '/Document/' + documentId + '/Service/' + id,

    ).pipe(
      catchError(this.errorHandl)
    )
  }
  deleteType(documentId: number, id: number) {
    return this.http.delete<Document>(api + '/Document/' + documentId + '/DocumentType/' + id,

    ).pipe(

      catchError(this.errorHandl)
    )
  }
  deleteNature(documentId: number, id: number) {
    return this.http.delete<Document>(api + '/Document/' + documentId + '/DocumentNature/' + id,

    ).pipe(

      catchError(this.errorHandl)
    )
  }

  deleteAttachment(documentId: number, id: number) {
    return this.http.delete<Document>(api + '/Document/' + documentId + '/Attachment/' + id,

    ).pipe(

      catchError(this.errorHandl)
    )
  }



  getAttachment(documentId: number, attachmentId: number): Observable<Blob> {

    return this.http.get(api + "/Document/" + documentId + "/Attachment/" + attachmentId, { responseType: 'blob' });
  }


  getReport(params: any): Observable<Blob> {

    return this.http.get(api + "/Document/ExcelReport/", { params: params, responseType: 'blob' });
  }










}