import { Pipe, PipeTransform } from '@angular/core';
import { Document } from '../models/document';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(document: Document): string {

    if(document == undefined || document==null)
    return "";

    if(!(document.status?.description.includes("to") || document.status?.description.includes("by") || document.status?.description.includes("from")))
     return document.status?.description;

    var currentSignatory;
    var role="Requesting Officer"

    if(document.stage > 0){
      var currentSignatory = document.requestType.signatory.filter(a => a.order == document.stage);
      role = currentSignatory[0].role?.name;

      if(currentSignatory[0].role?.name.includes("Authorizing Officer")){
        role = "Authorizing Officer";
      }
        
    }
     



    var status = document.status?.description + " "+ role;

    return status;
  }

}
