import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { DepartmentService } from 'src/app/services/department.service';
import { DepartmentStateModel } from './department.state-model';
import { GetAllDepartment, GetDepartment, UpdateDepartment, CreateDepartment } from './department.actions';


@State<DepartmentStateModel>({
    name:'Department'
  })
@Injectable()
  export class DepartmentState{
  
    constructor(private service: DepartmentService){
  
    }
  
    @Selector()
    static departments(state:DepartmentStateModel){
      return state.departments.filter( object => object.active==true).sort((a,b)=> a.name.localeCompare(b.name));
    }

    @Selector()
    static Department(state:DepartmentStateModel){
      return state.Department;
    }
  
  
    @Action(GetAllDepartment)
    getdepartments({patchState}:StateContext<DepartmentStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          departments: result.data
        });
      }));
    }

    @Action(GetDepartment)
    getDepartmentMethodById({patchState}:StateContext<DepartmentStateModel>, {id}: GetDepartment){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          Department: result
        });
      }));
    }
  
    @Action(UpdateDepartment)
    updateDepartmentMethod({dispatch}:StateContext<DepartmentStateModel>,{update}:UpdateDepartment
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllDepartment());
            }
          )
        )
      }

      @Action(CreateDepartment)
      createDepartment({dispatch, patchState}:StateContext<DepartmentStateModel>,{create}:CreateDepartment
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllDepartment());
              }
            )
          )
        }
  
  
    
  
  }